import { getAuth0Token } from '../../providers/AuthProvider';
import { jwtDecode } from 'jwt-decode';

const PERMISSIONS = 'https://yunu.io/permissions'

const canUploadToOrganizationLevel = (): boolean => {
    const auth0Token = getAuth0Token();
    const tokenPayload: any = jwtDecode(auth0Token);

    const permissions = tokenPayload[PERMISSIONS];

    return permissions.includes("DataUpload.OrganizationLevel");
};


export {
    canUploadToOrganizationLevel
}