import { useAuth0 } from '@auth0/auth0-react';

/**
 * Logs out of Auth0 and navigates back to the Auth0 login page.
 * Implemented as a route so that other apps can easily call it.
 * 
 */
const Auth0Logout = () => {
    const { logout } = useAuth0();

    logout({ logoutParams: { returnTo: window.location.origin } });

    return null;
}

export default Auth0Logout;