import Rollbar from 'rollbar'
import { Payload } from 'rollbar'
import type { GuestUploader } from '../../sharedTypes/bucketUploadTypes';
import { StateCode } from './providers/FileStateProvider';
import { jwtDecode } from "jwt-decode";

const packageJson = require('../package.json');

const isLocalhost = window.origin.includes("localhost");

// @ts-ignore
const config = window.config;


const defaultPayload: Payload = {
  environment: config.rollbarEnv,
  client: {
    javascript: {
      code_version: packageJson.version,
      source_map_enabled: true
    }
  }
};

const rollbar = Rollbar.init({
  enabled: !isLocalhost,
  accessToken: config.rollbarClientSecret,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: defaultPayload
});

/**
 * Note this only runs if the user is logged in, not on guest uploader.
 */
const updateRollbarWithLoginInfo = (auth0Token: string) => {
  const tokenPayload: any = jwtDecode(auth0Token);

  const username = tokenPayload["https://yunu.io/username"];
  const userId = tokenPayload["https://yunu.io/user_id"];
  const org = tokenPayload["https://yunu.io/org"];
  const homeOrg = tokenPayload["https://yunu.io/home_org"]

  // Note, this merges with previous state.
  rollbar.configure({
    payload: {
      username,
      userInfo: {
        userId,
        org,
        homeOrg
      }
    }
  });
};

const updateRollbarWithGuestInfo = (guestUploader: GuestUploader) => {
  rollbar.configure({
    payload: {
      guestUploader: {
        customerId: guestUploader.customer_id,
        trialId: guestUploader.trial_id,
        trialPatientId: guestUploader.trial_patient_id,
        orderId: guestUploader.order_id
      }
    }
  });
}

const updateRollbarWithState = (uiStateCode: StateCode) => {
  rollbar.configure({
    payload: {
      uiStateCode
    }
  });
}

export default rollbar;
export {
  updateRollbarWithLoginInfo,
  updateRollbarWithGuestInfo,
  updateRollbarWithState
};
